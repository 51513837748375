import React from "react"
import { Link } from "gatsby"

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/" className="hover:opacity-50">
          <span role="img" aria-label="Go back to home house icon">
            🏡
          </span>
        </Link>
      </h1>
    )
  } else {
    header = (
      <Link className="hover:opacity-50" to="/">
        <span role="img" aria-label="Go back to home house icon">
          🏡
        </span>
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="text-center text-xl">{header}</header>
      <main>{children}</main>
      <footer className="text-center">
        <a className="border-b-2 border-gray-600 hover:opacity-50 cursor-pointer" onClick={() => window.scrollTo(0, 0)}>Back to Top</a>
        <div>
          <Link to="/" className="hover:opacity-50 transform hover:scale-110">
            <span role="img" aria-label="Go back to home house icon">
              🏡
            </span>
          </Link>
        </div>
        © {new Date().getFullYear()} Lucas Puskaric
      </footer>
    </div>
  )
}

export default Layout
